<template>
  <div class="pt-4 px-4 pb-20">
    <div class="mb-5">
      <div :class="[$theme.activeTabBg, $theme.activeTabTextColor, 'px-2 py-1 rounded-md text-sm inline-flex']">
        {{ $t('level') }} {{ currentLevel }} ({{ levelInfo && levelInfo.percentage }}%)
      </div>
      <h1 class="text-3xl font-bold">
        {{ detailData && detailData.user.name }}
      </h1>
      <div class="opacity-50">
        {{ detailData && $t(detailData.user.app_status.toLowerCase()) }}
      </div>
    </div>

    <template v-if="loading">
      <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div>
        <div class="rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
          <div class="grid-cols-2 grid">
            <div :class="[$theme.cardBorderColor, 'border-r pr-4']">
              <div class="opacity-50 text-sm">{{ $t('referrals') }}</div>
              <div class="text-2xl font-bold">{{ detailData.user.ref_count }}</div>
            </div>
            <div class="pl-4">
              <div class="opacity-50 text-sm">{{ $t('income') }}</div>
              <div class="text-2xl font-bold">{{ formattedAmount(detailData.completed_amount) }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="mt-6 mb-3 opacity-50">{{ $t('referrals') }}</div>
    <template v-if="loadingReferrals">
      <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <template v-if="referrals.data.length > 0">
        <div v-for="referral in referrals.data" :key="referral.id" class="mb-3">
          <ReferralItem :item="referral" :level="parseInt(level) + 1" />
        </div>
      </template>
      <template v-else>
        <div class="rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
          <p class="text-center">{{ $t('user_no_referrals') }}</p>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LoadingBlock from "@/components/LoadingBlock.vue";
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import ReferralItem from "@/components/ref/ReferralItem.vue";
import {formattedAmount} from "@/helpers/numberHelper";
import {getRefLevelStats} from "@/helpers/userHelper";

export default {
  components: {
    ReferralItem,
    LoadingBlock,
  },

  watch: {
    '$route.params.id'(newId, oldId) {
      if (newId !== oldId) {
        this.loadRefData();
      }
    },
    '$route.query.level'(newVal, oldVal) {
      this.level = newVal || oldVal;
    }
  },

  computed: {
    currentLevel() {
      return this.$route.query.level ? parseInt(this.$route.query.level) : 1;
    },
    levelInfo() {
      return getRefLevelStats(this.$user, this.level);
    }
  },

  methods: {
    loadRefData() {
      const id = this.$route.params.id;

      this.$api.get(`/referral/${id}`).then(result => {
        console.log('result', result.data);
        this.detailData = result.data;
      }).finally(() => {
        this.loading = false;
        this.loadReferrals();
      })
    },

    loadReferrals() {
      this.$api.get('/referrers', {
        params: {
          user_id: this.detailData.user.id
        }
      }).then(result => {
        console.log(result.data);
        this.referrals = result.data;
        this.loadingReferrals = false;
      });
    }
  },

  data() {
    return {
      loading: true,
      loadingReferrals: true,
      detailData: null,
      referrals: null,
      level: 1,
      formattedAmount
    }
  },

  mounted() {
    console.log(this.$route.query.level);
    console.log(this.$user.ref_stats);

    this.level = this.$route.query.level || 1;

    this.loadRefData();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
};
</script>