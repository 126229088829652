<template>
  <router-link :to="`/referral/${item.id}?level=${level}`" class="rounded-xl flex items-center justify-between px-2 py-2" :class="[$theme.cardBg]">
    <div class="flex items-center">
      <div>
        <div class="w-10 h-10 rounded-full flex items-center justify-center" :class="[$theme.avatarBg]">
          {{ item.user ? item.user.name[0] : '?' }}
          <template v-if="item.total_completed_amount">
            <span :class="[$theme.successPnlColor, 'ml-2']">+{{ formattedAmount(item.total_completed_amount) }}</span>
          </template>
        </div>
      </div>
      <div class="pl-3">
        {{ item.user ? item.user.name : '' }} ({{ item.user.ref_count }})

        <div class="text-sm" :class="[item.user.app_status === 'completed' ? $theme.successPnlColor : 'opacity-50']">
          {{ $t(item.user.app_status.toLowerCase()) }}
        </div>
      </div>
    </div>
    <div class="flex items-center flex-col">
      <div>
        <ChevronRightIcon class="w-5 opacity-20" />
      </div>
    </div>
  </router-link>
</template>

<script>
import {ChevronRightIcon} from '@heroicons/vue/24/outline';
import {formattedAmount} from '@/helpers/numberHelper';

export default {
  methods: {
    formattedAmount() {
      return formattedAmount
    }
  },
  props: [
    'item',
    'level',
  ],

  components: {
    ChevronRightIcon,
  },
}
</script>